import Vue from "vue";
import VueRouter from "vue-router";
//import Info from "../views/Info.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "info",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/entradas",
    name: "entradas",
    component: () => import("../views/Entradas.vue"),
  },
  {
    path: "/contacto",
    name: "contacto",
    component: () => import("../views/Contacto.vue"),
  },
  {
    path: "/speakers",
    name: "speakers",
    component: () => import("../views/Speakers.vue"),
  },
  {
    path: "/donde",
    name: "donde",
    component: () => import("../views/Donde.vue"),
  },
  {
    path: "/certificados",
    name: "certificados",
    component: () => import("../views/Certificados.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
